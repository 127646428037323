'use client'

import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

import { Box, ButtonPill, ContentText, fontSizes, spacing } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { useTheme } from '@/shared/hooks/useTheme'

import { Section } from '../Section'
import { StyledHeading } from '../StyledHeading'

export type RedirectPageProps = { redirectUrl: string }

export const RedirectPage = ({ redirectUrl }: RedirectPageProps) => {
  const t = useTranslations('redirectPage')
  const { colors } = useTheme()

  useEffect(() => {
    if (/^(https?:\/\/)?([\w-]+\.)*fortum\.com(\/|$)/.test(redirectUrl)) {
      location.assign(redirectUrl)
    }
  }, [redirectUrl])

  return (
    <Section justifyContent="center" backgroundColor={colors.backgroundSecondary} pt={spacing.m}>
      <Box mb={spacing.xl} textAlign="center">
        <StyledHeading level={1} styledAs={2} mb={spacing.xs}>
          {t('title')}
        </StyledHeading>
        <ContentText fontSize={fontSizes.l}>
          {t('description')} ({redirectUrl})
        </ContentText>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={{ default: 'column', s: 'row' }}
        gap={{ default: spacing.xxs, m: spacing.m }}
        mb={spacing.xxl}
      >
        <ButtonPill tag={Link} href={redirectUrl} status="secondary" whiteSpace="nowrap">
          {t('goToSite')}
        </ButtonPill>

        <ButtonPill tag={Link} href="/" whiteSpace="nowrap">
          {t('backToFortum')}
        </ButtonPill>
      </Box>
    </Section>
  )
}
